#kt_wrapper {
    background-color: white !important;
}

#kt_content_container{
    position: relative;
}

.toast {
    opacity: 1 !important;
}

.btn i { 
    vertical-align: unset !important;
}

.loggedUserInitials {
    background: #2d2d50;
    color: white;
    opacity: 1; 
    display: inline-block; 
    font-weight: 600; 
    border-radius: 50%; 
    vertical-align: middle; 
    margin-right: 0.5em; 
    width: 50px; 
    height: 50px; 
    line-height: 50px; 
    text-align: center; 
}

.form-control-select {
    @extend .form-control;
    border: none !important;
    padding-left: 0 !important;
}

.menu-arrow{
    color: white;
}